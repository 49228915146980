<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Formato de impresión</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <v-row>
          <v-col md="6">
            <input type="file" @change="handleFileUpload" />
            <button @click="generatePdf">Generate Invoice</button>
            <v-row class="mt-5">
              <v-col>
                <v-slider
                  v-model="fontSize"
                  label="Tamaño de fuente"
                  max="50"
                  min="0"
                  thumb-label="always"
                ></v-slider>

                <v-slider
                  v-model="margin"
                  label="Margen eje x"
                  :max="maxWidth"
                  min="0"
                  thumb-label="always"
                ></v-slider>

                <v-slider
                  label="Margen eje y"
                  :max="maxHeight"
                  min="0"
                  thumb-label="always"
                  v-model="marginTop"
                ></v-slider>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <iframe
              v-if="pdfUrl"
              :src="'https://repositoriotec.tec.ac.cr/bitstream/handle/2238/213/Tesis%20completa.pdf'"
              style="width: 100%; height: 600px"
            />
            <iframe
              v-if="pdfUrl"
              :src="pdfUrl"
              style="width: 100%; height: 600px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { PDFDocument, StandardFonts } from "pdf-lib";
import { formatCurrency } from "../helpers/formatters";

export default {
  data() {
    return {
      pdfTemplate: null, // To store the uploaded PDF file
      invoiceData: {}, // Store your fetched invoice data here
      pdfUrl: null,
      fontSize: 12,
      margin: 50,
      marginTop: 50,
      maxWidth: 200,
      maxHeight: 200,
    };
  },
  methods: {
    formatMoney(x) {
      return formatCurrency(x);
    },
    // Handle file upload
    handleFileUpload(event) {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.onload = () => {
        this.pdfTemplate = new Uint8Array(fileReader.result);
      };
      fileReader.readAsArrayBuffer(file);
    },

    // Generate the PDF with invoice data
    async generatePdf() {
      try {
        const url =
          "https://www.renfe.com/content/dam/renfe/es/General/PDF-y-otros/Ejemplo-de-descarga-pdf.pdf";
        // Step 1: Fetch the PDF
        const response = await fetch(url);
        const pdfBytes = await response.arrayBuffer();

        // Step 2: Load the PDF
        const pdfDoc = await PDFDocument.load(pdfBytes);
        console.log("pdfDoc", pdfDoc);

        return false;

        /*if (!this.pdfTemplate) {
          alert("Please upload a PDF template");
          return;
        }

        // Load the uploaded PDF
        const pdfDoc = await PDFDocument.load(this.pdfTemplate);
        const pages = pdfDoc.getPages();
        let firstPage = pages[0];

        if (!firstPage) {
          return null;
        }

        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        firstPage = this.drawPage(
          {
            items: [
              {
                description: "Item 1",
                quantity: 2,
                unitPrice: 10,
              },
              {
                description: "Item 2",
                quantity: 3,
                unitPrice: 20,
              },
            ],
          },
          { page: firstPage, font }
        );

        const pdfBytes = await pdfDoc.save();
        this.pdfUrl = URL.createObjectURL(
          new Blob([pdfBytes], { type: "application/pdf" })
        );*/
      } catch (error) {
        console.log("error", error);
      }
    },

    drawPage(invoiceData, { page, font }) {
      const { width, height } = page.getSize();
      this.maxWidth = width;
      this.maxHeight = height;

      const fontSize = Number(this.fontSize);
      const margin = Number(this.margin);
      const marginTop = Number(this.marginTop);
      const columns = width / 4;
      const lineHeight = fontSize + this.fontSize / 3;

      page.drawText(invoiceData?.name || "", {
        x: margin,
        y: height - marginTop,
        size: fontSize,
        font,
      });
      page.drawText(invoiceData?.specialties || "", {
        x: margin,
        y: height - marginTop - lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(invoiceData?.phone || "", {
        x: margin,
        y: height - marginTop - 2 * lineHeight,
        size: fontSize,
        font,
      });

      page.drawText(`Fecha: ${invoiceData?.date}`, {
        x: margin,
        y: height - marginTop - 4 * lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(`Código: ${invoiceData?.code}`, {
        x: margin,
        y: height - marginTop - 5 * lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(`Sede: ${invoiceData?.address}`, {
        x: margin,
        y: height - marginTop - 6 * lineHeight,
        size: fontSize,
        font,
      });
      page.drawText(`Paciente: ${invoiceData?.patientName}`, {
        x: margin,
        y: height - marginTop - 7 * lineHeight,
        size: fontSize,
        font,
      });

      // Add table header
      const tableTop = height - marginTop - 10 * lineHeight;
      page.drawText("Descripción", {
        x: margin,
        y: tableTop,
        size: fontSize,
        font,
      });
      page.drawText("Cantidad", {
        x: margin + columns,
        y: tableTop,
        size: fontSize,
        font,
      });
      page.drawText("Precio Und.", {
        x: margin + columns * 2,
        y: tableTop,
        size: fontSize,
        font,
      });
      page.drawText("Importe", {
        x: margin + columns * 3,
        y: tableTop,
        size: fontSize,
        font,
      });

      // Add table items
      let itemY = tableTop - lineHeight;
      invoiceData?.items?.forEach((item) => {
        page.drawText(item?.description, {
          x: margin,
          y: itemY,
          size: fontSize,
          font,
        });
        page.drawText(String(item?.quantity), {
          x: margin + columns,
          y: itemY,
          size: fontSize,
          font,
        });
        page.drawText(`${this.formatMoney(invoiceData, item?.unitPrice)}`, {
          x: margin + columns * 2,
          y: itemY,
          size: fontSize,
          font,
        });
        page.drawText(
          `${this.formatMoney(invoiceData, item?.quantity * item?.unitPrice)}`,
          {
            x: margin + columns * 3,
            y: itemY,
            size: fontSize,
            font,
          }
        );
        itemY -= lineHeight;
      });

      page.drawText(
        `Total: ${this.formatMoney(invoiceData, invoiceData?.total)}`,
        {
          x: margin + columns * 3,
          y: itemY - lineHeight,
          size: fontSize,
          font,
        }
      );
      page.drawText(`Moneda: ${invoiceData?.currency || "DOP"}`, {
        x: margin + columns * 3,
        y: itemY - lineHeight - 18,
        size: fontSize,
        font,
      });
    },
  },
  watch: {
    fontSize() {
      this.generatePdf();
    },
    margin() {
      this.generatePdf();
    },
    marginTop() {
      this.generatePdf();
    },
  },
};
</script>
